import CommiteeAskSupervisorForm from './ComitteeAskSupervisorForm';

const CommiteeAskSupervisorDecision = ({ status, offerID }) => {
  return (
    <CommiteeAskSupervisorForm
      offerID={offerID}
      status={status}
    />
  );
};

export default CommiteeAskSupervisorDecision;
