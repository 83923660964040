import apiInstance from './api';

const committeControlDecisionApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getDecidedCommittees: build.query({
      query: (offerID) => `ou_committee_opinions/last/${offerID}`,
      providesTags: ['CommitteeGiveOpinion'],
    }),
    getAskedCommittees: build.query({
      query: (id) => `ou_committee_opinions/decidables/${id}`,
      providesTags: ['AskedCommittees'],
    }),
    sendCommitteeControlDecision: build.mutation({
      query: ({ id, body }) => ({
        url: `article_offers/decision/committee_control/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'CommitteeGiveOpinion', 'ActivitiesList', 'AuthorArticles', 'SupervisorOpinion'],
    }),
    giveCommitteeOpinion: build.mutation({
      query: ({ committeeID, body }) => ({
        url: `ou_committee_opinion_decisions/decide/${committeeID}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ArticleOffers', 'ArticleOfferDetail', 'CommitteeGiveOpinion', 'ActivitiesList', 'AuthorArticles', 'AskedCommittees'],
    }),
  }),
  overrideExisting: true,
});

export default committeControlDecisionApi;
