import React from 'react';

import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

/**
 * Madde Gönder Butonu
 */
const SendItemSteps = ({ offerId, disabled, path = 'yeni-icerik-ekle' }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="modalBtnGrp">
      <Button
        block
        disabled={disabled}
        className="modalBtnStyle"
      >
        <Link to={`/${path}/${offerId}`}>{formatMessage({ id: 'article.status.sendItem' })}</Link>
      </Button>
    </div>
  );
};

export default SendItemSteps;
