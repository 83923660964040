import ContentUpload from 'module/ContentUpload/ContentUpload';

import { useEffect } from 'react';

import { Button, Form, Input } from 'antd';
import { useGetArticleContentQuery, useSendCommitteeControlDecisionMutation } from 'services';
import { useIntl } from 'react-intl';
import { CircularProgress } from 'components/CircularProgress';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useNavigate } from 'react-router-dom';

import TimeInfoCard from './TimeInfoCard';

const { TextArea } = Input;

const CommiteeAskResponsibleForm = ({ offerID, status }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, isLoading } = useGetArticleContentQuery({
    offerID,
  });

  const [sendCommitteeControlDecision] = useSendCommitteeControlDecisionMutation();

  const onFinish = async (values) => {
    const newValues = {
      status,
      additionalDay: Number(values.deliveryDate),
      description: values.description,
    };
    const { error } = await sendCommitteeControlDecision({ id: offerID, body: newValues });
    if (error) return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
    navigate('/madde-teklifleri/tum-maddeler');
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        itemFile: data.contents.find((item) => item.type === 0),
        idCard: data.contents.find((item) => item.type === 1),
        uvkaFile: data.contents.find((item) => item.type === 3),
      });
    }
  }, [data, form]);

  if (isLoading && !data) return <CircularProgress />;
  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
    >
      <TimeInfoCard offerID={offerID} />
      <ContentUpload id={offerID} />
      <Form.Item
        name="description"
        label="Açıklama"
      >
        <Form.Item
          name="description"
          className="gx-mb-4"
          rules={[
            {
              min: 50,
              message: formatMessage({
                id: 'article.reasonLengthMessageShort',
              }),
            },
          ]}
          normalize={(value) => (value.trim() === '' ? undefined : value)}
        >
          <TextArea
            rows={8}
            placeholder="Notunuz varsa girebilirsiniz"
            showCount
          />
        </Form.Item>
      </Form.Item>
      <Button
        block
        htmlType="submit"
      >
        Gönder
      </Button>
    </Form>
  );
};

export default CommiteeAskResponsibleForm;
