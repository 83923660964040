import { Card } from 'antd';
import dayjs from 'dayjs';

const SupervisorStatusItem = ({ deliveryDate, supervisorFullname, finishDate }) => {
  return (
    <Card className=" gx-mt-3">
      <div className="gx-d-flex gx-align-items-center">
        <div className="gx-mr-5">Madde Sorumlusu: {supervisorFullname}</div>
        <div className="gx-mr-5">Süre: {deliveryDate} gün</div>
        <div>Teslim tarihi: {dayjs(finishDate).format('DD/MM/YYYY')}</div>
      </div>
    </Card>
  );
};

export default SupervisorStatusItem;
