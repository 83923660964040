import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Card } from 'antd';
import dayjs from 'dayjs';
import { FormattedHTMLMessage } from 'react-intl';

import SupervisorStatusItem from './SupervisorStatusItem';

const SupervisorOpinion = ({ data, isSupervisor }) => {
  const handleOrderStatusText = (opinion) => {
    switch (opinion) {
      case 'pending':
        return isSupervisor ? 'pendingSupervisorContent.supervisorDescription' : 'pendingSupervisorContent.description';

      case 'approved':
        return isSupervisor ? 'supervisorSend.supervisorDescription' : 'supervisorSend.description';
      case 'canceled':
        return 'supervisorCanceled.description';
      case 'notAnswered':
        return isSupervisor ? 'supervisorNotAnswered.supervisorDescription' : 'supervisorNotAnswered.description';
      default:
        break;
    }
  };

  const content = {
    pending: {
      icon: <ClockCircleOutlined />,
      key: 'artıcle.order',
    },
    approved: {
      icon: <CheckCircleOutlined />,
      key: 'artıcle.order',
    },
    canceled: {
      icon: <CloseCircleOutlined />,
      key: 'artıcle.order',
    },
    notAnswered: {
      icon: <ClockCircleOutlined />,
      key: 'article.order',
    },
  };

  return (
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-mb-3">
        <span className="gx-fs-xxl">{content[data?.opinion]?.icon}</span>
        <div className="gx-fs-xxl gx-ml-2">Madde Sorumlusu</div>
      </div>
      <FormattedHTMLMessage
        id={`${content[data?.opinion]?.key}.${handleOrderStatusText(data?.opinion)}`}
        values={{
          date: dayjs(data?.finishDate).format('DD/MM/YYYY'),
          diff: dayjs(data?.inishDate).diff(dayjs(), 'days'),
        }}
      />
      {data && data.fromDescription && (
        <div className="gx-mt-3">
          <div className=" gx-mb-2 gx-fs-lg">Açıklama</div>
          <Alert
            type="info"
            description={data.fromDescription}
          />
        </div>
      )}
      <SupervisorStatusItem
        supervisorFullname={data?.supervisor?.fullName && data.supervisor.fullName}
        deliveryDate={data?.articleLengthDay}
        finishDate={data?.finishDate}
      />
    </Card>
  );
};

export default SupervisorOpinion;
