import { useState } from 'react';

import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { committeeDecisions } from 'utils';

const DecisionLink = ({ path, children }) => (
  <Link
    className="modalBtnStyle ant-btn"
    to={path}
  >
    {children}
  </Link>
);

const decisionItems = (offerId, commiteeID, authorID) => {
  const decisions = [
    {
      id: committeeDecisions.approved,
      label: <FormattedMessage id="article.actionBtn.decisionAccept" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.approved}`,
    },
    {
      id: committeeDecisions.opinion,
      label: <FormattedMessage id="article.actionBtn.decisionAskOpinion" />,
      path: `/heyet-karar/${authorID}/${offerId}/${commiteeID}/${committeeDecisions.opinion}`,
    },
    {
      id: committeeDecisions.sendSupervisor,
      label: <FormattedMessage id="article.actionBtn.decisionSendSupervisor" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.sendSupervisor}`,
    },
    {
      id: committeeDecisions.revert,
      label: <FormattedMessage id="article.actionBtn.decisionRevent" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.revert}`,
    },
    {
      id: committeeDecisions.rewrite,
      label: <FormattedMessage id="article.actionBtn.decisionRewrite" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.rewrite}`,
    },
    {
      id: committeeDecisions.rejected,
      label: <FormattedMessage id="article.actionBtn.decisionReject" />,
      path: `/heyet-karar/${authorID}/${offerId}/${committeeDecisions.rejected}`,
    },
  ];
  return decisions;
};
/**
 * IH Heyet Kararları
 */
const CommiteeDecisionActions = ({ offerId, commiteeID, step }) => {
  const decisions = decisionItems(offerId, commiteeID, step);

  const [showButton, setShowButton] = useState(false);

  return (
    <>
      {showButton && (
        <div className="modalBtnGrp">
          {decisions.map((decision) => (
            <DecisionLink
              key={decision.id}
              path={decision.path}
            >
              {decision.label}
            </DecisionLink>
          ))}
        </div>
      )}
      <Button
        className={showButton ? 'gx-my-4' : ''}
        type={showButton ? 'primary' : 'default'}
        onClick={() => setShowButton(!showButton)}
        block
      >
        {showButton ? 'Vazgeç' : 'Karar Ver'}
      </Button>
    </>
  );
};

export default CommiteeDecisionActions;
