import { useEffect } from 'react';

import ComitteeRewriteDecision from 'components/ArticleOrder/status/ComitteeRewriteDecision';
import RevertCommitteeDecision from 'components/ArticleOrder/status/RevertCommitteeDecision';
import CommiteeRejectDecision from 'components/ArticleOrder/status/CommiteeRejectDecision';
import { Link, useParams } from 'react-router-dom';
import { committeeDecisions } from 'utils';
import CommiteeAskSupervisorDecision from 'components/ArticleOrder/status/CommiteeAskSupervisorDecision';

import ApprovedCommiteeDecision from './ApprovedCommiteeDecision';

const handleRenderRoute = (status, offerID, step) => {
  switch (status) {
    case committeeDecisions.approved:
      return (
        <ApprovedCommiteeDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.approved}
        />
      );
    case committeeDecisions.revert:
      return (
        <RevertCommitteeDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.revert}
        />
      );
    case committeeDecisions.rewrite:
      return (
        <ComitteeRewriteDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.rewrite}
        />
      );
    case committeeDecisions.rejected:
      return (
        <CommiteeRejectDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.rejected}
        />
      );
    case committeeDecisions.sendSupervisor:
      return (
        <CommiteeAskSupervisorDecision
          offerID={offerID}
          step={step}
          status={committeeDecisions.sendSupervisor}
        />
      );
    default:
      <></>;
  }
};

const GiveDecisionView = () => {
  const { offerID, step, status } = useParams();

  useEffect(() => {
    document.getElementById('scrollToTop').scrollIntoView(true);
  }, []);

  return (
    <div id="scrollToTop">
      <Link
        className="ant-btn"
        to={`/madde-detay/${offerID}`}
      >
        Geri
      </Link>
      {handleRenderRoute(Number(status), Number(offerID), Number(step))}
    </div>
  );
};

export default GiveDecisionView;
