import apiInstance from './api';
const stiplatedTimeApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getStiplatedTime: build.query({
      query: () => '/stipulated_time',
      providesTags: ['StiplatedTimes'],
    }),
    getSupervisorStiplatedTime: build.query({
      query: () => `/stipulated_time/supervisor`,
      providesTags: ['SupervisorStiplatedTimes'],
    }),
  }),
  overrideExisting: true,
});

export default stiplatedTimeApi;
