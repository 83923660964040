import { fileUpload } from 'fetcher';

/**
 * Dosya yüklenirken dosya sırasının bozulmaması için yükleme
 * işleminin sırayla yapılması gerekiyor
 */
export async function uploadMedia(fileList) {
  const uploadedList = await fileList.reduce(async (acc, file) => {
    const res = await acc;
    const formData = new FormData();
    formData.append('media', file.originFileObj);

    const uploaded = await fileUpload('/form_media/upload', formData);
    return [...res, uploaded.data];
  }, Promise.resolve([]));

  return uploadedList;
}

function chunkFile(file, chunkSize = 1000000) {
  const fileChunks = [];
  let start = 0;
  let end = chunkSize;

  while (start < file.size) {
    fileChunks.push(file.slice(start, end));
    start = end;
    end = start + chunkSize;
  }

  return fileChunks;
}

export async function chunkUploadMedia(type, fileList, body = {}, chunkSize = 2000000) {
  const uploadedList = await fileList.reduce(async (acc, file) => {
    const res = await acc;

    const fileChunks = chunkFile(file.originFileObj || file, chunkSize);
    const uuid = crypto.randomUUID();

    for (let i = 0; i < fileChunks.length; i++) {
      const chunk = fileChunks[i];

      try {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('uuid', uuid);
        formData.append('index', i + 1);
        formData.append('total', fileChunks.length);
        formData.append('orig', file.name);

        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, value);
        });

        const uploaded = await fileUpload(`/upload/${type}`, formData);
        if (i === fileChunks.length - 1) {
          return [...res, uploaded.data];
        }
      } catch (error) {
        console.error(`Chunk yükleme hatası (Dosya: ${file.name}, Index: ${i}):`, error);
      }
    }
  }, Promise.resolve([]));

  return uploadedList;
}
