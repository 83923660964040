import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Row, Table } from 'antd';
import UploadedFile from 'components/SendItems/UploadedFile';
import dayjs from 'dayjs';
import { fileDownload } from 'fetcher';
import { useGetActivitiesListQuery } from 'services';
import { downloadBlob } from 'utils';
import RevertAnswerItem from 'components/ArticleOffer/offer/ArticleStatus/RevertAnswerItem';

import ComitteeComments from './ComitteeComments';

const { Panel } = Collapse;

const handleBlobDownload = async (fileUrl, fileName) => {
  const { data } = await fileDownload(fileUrl);
  downloadBlob(data, fileName);
};

const ActivityHistory = ({ id }) => {
  const { data, isLoading } = useGetActivitiesListQuery({
    entityId: id,
  });

  const column = [
    {
      title: 'Tarih',
      dataIndex: 'date',
      render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Kullanıcı',
      dataIndex: 'user',
    },
    {
      title: 'Birim',
      dataIndex: 'unit',
    },
    {
      title: 'İşlem',
      dataIndex: 'decision',
    },
  ];
  return (
    <Card>
      <h4>Geçmiş Hareketler</h4>
      <Table
        bordered
        pagination={false}
        loading={isLoading}
        rowKey={(record) => record.id}
        dataSource={data?.data}
        columns={column}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Row
                className="gx-p-3"
                gutter={[24, 32]}
              >
                {record?.authorContent?.contents?.map(
                  (item) =>
                    item.type !== 2 && (
                      <>
                        <Col
                          sm={24}
                          md={8}
                          key={item.id}
                        >
                          <>{item.type === 0 ? 'Madde' : (item.type === 1 && 'Kimlik Kartı') || (item.type === 3 && 'ÜVKA')} Dosyası</>

                          <UploadedFile
                            content={item.media[0].name}
                            fontStyle={{
                              fontSize: 14,
                            }}
                            extra={
                              <Button
                                type="link"
                                style={{
                                  marginBottom: 0,
                                }}
                                size="large"
                                onClick={() => handleBlobDownload(item.media[0].fileFullUrl, item.media[0].name)}
                                icon={<DownloadOutlined />}
                              />
                            }
                          />
                        </Col>
                      </>
                    )
                )}
              </Row>
              <Row
                className="gx-p-3"
                gutter={24}
              >
                <Col span={24}>
                  {record?.authorContent?.authorDescription && (
                    <Collapse>
                      <Panel header="Açıklama">{record.authorContent.authorDescription}</Panel>
                    </Collapse>
                  )}
                </Col>
                {Array.isArray(record.revertReasons) && record.revertReasons.length > 0 && (
                  <Col
                    className="gx-mt-3"
                    span={24}
                  >
                    <h2>İade Gerekçeleri</h2>
                    <ul>
                      {record.revertReasons.map((item, index) => (
                        <RevertAnswerItem
                          // unique bir id değeri olmadığı için index'i key olarak verdim
                          key={index}
                          title={item.title}
                          answer={item.answer}
                        />
                      ))}
                    </ul>
                  </Col>
                )}
                <Col span={24}>
                  {record?.authorContent?.mediaOfferDescription && (
                    <Collapse>
                      <Panel header="Medya Teklifi Görüşü">{record.authorContent.mediaOfferDescription}</Panel>
                    </Collapse>
                  )}
                </Col>
                <Col span={24}>{record?.lastOpinion && <ComitteeComments data={record?.lastOpinion.decisions} />}</Col>
              </Row>
            </>
          ),
          rowExpandable: (record) =>
            record.authorContent?.contents?.length > 0 ||
            record.revertReasons?.length > 0 ||
            record.authorContent?.authorDescription ||
            record.authorContent?.mediaOfferDescription ||
            record.lastOpinion,
        }}
      />
    </Card>
  );
};

export default ActivityHistory;
