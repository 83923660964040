import { higherCommittee } from 'constant/static';

import apiInstance from './api';

const currentUserApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query({
      query: () => '/current_user',
      transformResponse: (response) => ({
        ...response,
        isSuperAdmin: response.roles.includes('ROLE_SUPER_ADMIN'),
        isAdmin: response.roles.includes('ROLE_SUPER_ADMIN') || response.roles.includes('ROLE_ADMIN'),
        isResourceManager: response.roles.includes('ROLE_ADMIN') || response.roles.includes('ROLE_SUPER_ADMIN') || response.roles.includes('ROLE_ERESOURCE'),
        isSupervisor: response.isSupervisor,
        isDAH: response.roles.includes('ROLE_DAH'),
        isAYK: response.roles.includes('ROLE_AYK'),
        isEncyclopedia: response.roles.includes('ROLE_ENCYCLOPEDIA'),
        isComittee: response.roles.includes('ROLE_COMMITTEE'),
        isE2User: response.roles.includes('ROLE_USER'),
        isOrderUnit: response.roles.includes('ROLE_ORDER_UNIT'),
        isAuthor: response.roles.includes('ROLE_AUTHOR'),
        isEUMamager: response.roles.includes('ROLE_EU_MANAGER'),
        isPubManager: response.roles.includes('ROLE_PUB_MANAGER'),
        isPostOfferWatcher: response.roles.includes('ROLE_POST_OFFER_WATCHER'),
        isManager:
          response.roles.includes('ROLE_ADMIN') ||
          response.roles.includes('ROLE_SUPER_ADMIN') ||
          response.memberships.some((item) => item.type === 1 || item.type === 0),
        canOffer:
          response.roles.includes('ROLE_ADMIN') ||
          response.roles.includes('ROLE_SUPER_ADMIN') ||
          response.roles.includes('ROLE_ENCYCLOPEDIA') ||
          response.memberships.some((item) => !higherCommittee.includes(item.committee.id) && (item.type === 1 || item.type === 0)),
        canWhichCommitteeOffer: response.memberships.filter((item) => !higherCommittee.includes(item.committee.id) && (item.type === 1 || item.type === 0)),
        canSeeAfterCopyright:
          response.roles.includes('ROLE_ADMIN') ||
          response.roles.includes('ROLE_SUPER_ADMIN') ||
          response.roles.includes('ROLE_POST_OFFER_WATCHER') ||
          response.roles.includes('ROLE_EU_EMPLOYEE') ||
          response.roles.includes('ROLE_PUB_EMPLOYEE') ||
          response.roles.includes('ROLE_EU_MANAGER') ||
          response.roles.includes('ROLE_PUB_MANAGER') ||
          response.memberships.some((item) => item.type === 1 || item.type === 0),
        encyclopediaUnitEmployee: response.roles.includes('ROLE_EU_EMPLOYEE'),
        publusmentUnitEmployee: response.roles.includes('ROLE_PUB_EMPLOYEE'),
        pubManager: response.roles.includes('ROLE_PUB_MANAGER'),
        euManager: response.roles.includes('ROLE_EU_MANAGER'),
        pubEmployee: response.roles.includes('ROLE_PUB_EMPLOYEE'),
        euEmployee: response.roles.includes('ROLE_EU_EMPLOYEE'),
        afterCopyrightRoles:
          response.roles.includes('ROLE_POST_OFFER_WATCHER') ||
          response.roles.includes('ROLE_EU_MANAGER') ||
          response.roles.includes('ROLE_PUB_MANAGER') ||
          response.roles.includes('ROLE_EU_EMPLOYEE') ||
          response.roles.includes('ROLE_PUB_EMPLOYEE'),
        memberships: response.memberships,
      }),
      providesTags: ['CurrentUser'],
    }),

    updateCurrentUser: build.mutation({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
  }),
  overrideExisting: true,
});
export default currentUserApi;
