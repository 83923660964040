import apiInstance from './api';

const supervisorOpinionApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getSupervisorOpinion: build.query({
      query: (id) => `/article_offers/${id}/supervisor_opinion`,
      providesTags: ['SupervisorOpinion'],
    }),
    sendSupervisorOpinion: build.mutation({
      query: ({ id, body }) => ({
        url: `supervisor_opinions/${id}/give`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SupervisorOpinion', 'ActivitiesList', 'ArticleOfferDetail'],
    }),
  }),
  overrideExisting: true,
});

export default supervisorOpinionApi;
