import { useState } from 'react';

import { Button } from 'antd';
import { useSendCommitteeControlDecisionMutation } from 'services';
import { committeeDecisions } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

/**
 * IH madde sorumlusundan maddeyi geri cekme islemini buradan yapabilmektedir.
 */
const RevertFromSupervisorAction = ({ offerId }) => {
  const [showButton, setShowButton] = useState(false);
  const [sendCommitteeControlDecision] = useSendCommitteeControlDecisionMutation();
  const dispatch = useDispatch();

  const handleRevert = async () => {
    const { error } = await sendCommitteeControlDecision({
      id: offerId,
      body: {
        status: committeeDecisions.revert,
      },
    });
    if (error) {
      return dispatch(fetchError(error.message));
    }
    setShowButton(false);
  };

  return (
    <div>
      {showButton && (
        <div className="modalBtnGrp gx-w-100">
          <div className="gx-d-flex gx-flex-column gx-align-items-center gx-align-items-center gx-w-100">
            <div className="gx-mb-3">Maddeyi “Madde Sorumlusundan Geri Çek” seçeneğini seçtiğinizde, madde İlim Heyeti karar sürecinin başına dönecektir.</div>
            <Button onClick={handleRevert}>Geri Çek</Button>
          </div>
        </div>
      )}
      <Button
        className={showButton ? 'gx-my-4' : ''}
        type={showButton ? 'primary' : 'default'}
        onClick={() => setShowButton(!showButton)}
        block
      >
        {showButton ? 'VAZGEÇ' : 'MADDDE SORUMLUSUNDAN GERİ ÇEK '}
      </Button>
    </div>
  );
};

export default RevertFromSupervisorAction;
