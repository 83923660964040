import { useEffect, useMemo, useState } from 'react';

import { CommentOutlined, DownloadOutlined, PictureOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useGetArticleContentQuery, useMakeAssigmentDecisionMutation, useSendNoteToAuthorMediaOfferMutation } from 'services';
import { Button, Col, Form, Input, Modal, Row, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { downloadBlob, openNotification } from 'utils';
import { fileDownload } from 'fetcher';
import UploadedMediaModal from 'components/SendItems/UploadedMediaModal';

const { TextArea } = Input;

const mediaTypeOptions = {
  1: 'Fotoğraf',
  2: 'Video',
  3: 'Ses Dosyası',
  4: 'Harita',
  5: 'Grafik',
  6: 'İnfografik',
  7: 'Plan',
  8: 'Nota',
  9: 'Bayrak',
  10: 'Resim',
  11: 'Minyatür',
  12: 'Hat',
  13: 'Yazma Sayfası',
};
const handleBlobDownload = async (fileUrl, fileName) => {
  const { data } = await fileDownload(fileUrl);
  downloadBlob(data, fileName);
};

const CommentModal = ({ makeComment, data, ...other }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const onFinish = async (values) => {
    const { error } = await makeComment(values);

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });

    other.onCancel();
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      note: data,
    });
  }, [form, data]);

  return (
    <Modal
      footer={null}
      destroyOnClose
      {...other}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="note"
          rules={[
            {
              required: true,
              message: 'Lütfen notunuzu giriniz',
            },
          ]}
          label="Medya teklifi notu"
        >
          <TextArea
            className="gx-mt-4"
            placeholder="Notunuzu giriniz"
            rows={5}
            showCount
          />
        </Form.Item>
        <Button
          className="gx-mt-4"
          block
          htmlType="submit"
        >
          Gönder
        </Button>
      </Form>
    </Modal>
  );
};
const NoteModal = ({ note, ...other }) => {
  return (
    <Modal
      title="Not"
      footer={null}
      destroyOnClose
      {...other}
    >
      <span>{note}</span>
    </Modal>
  );
};
const MediaOfferField = ({ offerID, userCanDecide = false, isHide = false }) => {
  const { data, isLoading } = useGetArticleContentQuery(
    {
      offerID,
    },
    {
      skip: !offerID,
    }
  );
  const [mediaModal, setMediaModal] = useState({
    visible: false,
    data: null,
  });
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [sendNoteToAuthorMediaOffer] = useSendNoteToAuthorMediaOfferMutation();

  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
    id: null,
  });
  const [noteModal, setNoteModal] = useState({
    visible: false,
    data: null,
  });

  const metadata = useMemo(() => {
    return data?.contents.filter((item) => item.type === 2);
  }, [data]);

  const makeComment = async (values) => {
    return await sendNoteToAuthorMediaOffer({
      mediaID: modalProps.id,
      data: { ...values },
    });
  };
  const [makeAssigmentDecision] = useMakeAssigmentDecisionMutation();

  const handleSubmit = async () => {
    const body = {
      opinion: 'approved',
    };

    const { error } = await makeAssigmentDecision({
      offerID,
      data: body,
    });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  const colums = [
    {
      title: 'Dosya türü',
      dataIndex: 'mediaType',
      key: 'key',
      width: 150,
      render: (text) => mediaTypeOptions[text],
    },
    {
      title: 'Medya Kaynağı',
      dataIndex: 'mediaSource',
      key: 'key',
    },
    {
      title: 'Medya Adı',
      dataIndex: 'media',
      key: 'key',
      render: (_, record) => (
        <>
          {record?.media && record?.media?.length > 1 ? (
            <div
              className="gx-d-flex gx-align-items-center"
              style={{
                gap: 8,
                cursor: 'pointer',
              }}
              onClick={() => setMediaModal({ visible: true, data: record.media })}
            >
              <span>{record?.media.length} medya</span>
              <PictureOutlined />
            </div>
          ) : (
            <div className="gx-d-flex gx-align-items-center">
              {record?.media &&
                record?.media.map((item) => {
                  return (
                    <span key={item.id}>
                      <span className={'gx-mr-2'}>{item.name}</span>
                      <DownloadOutlined onClick={() => handleBlobDownload(item.fileFullUrl, item.name)} />
                    </span>
                  );
                })}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Medya Linki',
      dataIndex: 'mediaLink',
      key: 'key',
      render: (text) => {
        return text ? (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={text}
          >
            {text}
          </a>
        ) : (
          <>-</>
        );
      },
    },
    {
      key: 'action',
      hidden: isHide || !userCanDecide,
      width: 50,
      render: (text, record) => (
        <Button
          type="text"
          size="large"
          onClick={() => setModalProps({ visible: true, id: record.id, data: record.note })}
          icon={<PlusSquareOutlined style={{ fontSize: '20px' }} />}
        />
      ),
    },
  ].filter((item) => !item.hidden);

  const noteColumn = [
    {
      title: 'Dosya türü',
      dataIndex: 'mediaType',
      key: 'key',
      width: 150,
      render: (text) => mediaTypeOptions[text],
    },
    {
      title: 'Medya Kaynağı',
      dataIndex: 'mediaSource',
      key: 'key',
    },
    {
      title: 'Medya Adı',
      dataIndex: 'media',
      key: 'key',
      render: (_, record) => (
        <>
          {record?.media && record?.media?.length > 1 ? (
            <div
              className="gx-d-flex gx-align-items-center"
              style={{
                gap: 8,
                cursor: 'pointer',
              }}
            >
              <span>{record?.media.length} medya</span>
              <PictureOutlined />
            </div>
          ) : (
            <div className="gx-d-flex gx-align-items-center">
              {record?.media &&
                record?.media.map((item) => {
                  return (
                    <span key={item.id}>
                      <span className={'gx-mr-2'}>{item.name}</span>
                      <DownloadOutlined />
                    </span>
                  );
                })}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Medya Linki',
      dataIndex: 'mediaLink',
      key: 'key',
      render: (text) => {
        return text ? (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={text}
          >
            {text}
          </a>
        ) : (
          <>-</>
        );
      },
    },
    {
      key: 'action',
      width: 50,
      render: (record) => (
        <Button
          type="text"
          onClick={() => setNoteModal({ visible: true, data: record.note })}
          icon={
            <CommentOutlined
              style={{
                fontSize: '20px',
              }}
            />
          }
        />
      ),
    },
  ];

  const dataSource =
    metadata &&
    metadata?.map((metadata) => ({
      ...metadata,
      key: metadata?.id,
      mediaType: metadata?.metadata.mediaType,
      mediaSource: metadata?.metadata.source,
      mediaLink: metadata?.metadata.linkUrl,
      media: metadata?.media?.map((item) => ({ name: item.name, id: item.id, fileFullUrl: item.fileFullUrl })),
      mediaDescription: metadata?.metadata?.description,
    }));

  const mediaHasNote = useMemo(() => {
    return dataSource && dataSource.filter((item) => item.note);
  }, [dataSource]);

  return (
    <>
      <Table
        loading={isLoading}
        scroll={{ x: 1000 }}
        key={(record) => record.key}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <h4>Madde Açıklaması</h4>
              <p>{record.mediaDescription}</p>
            </>
          ),
          rowExpandable: (record) => record.mediaDescription,
        }}
        bordered
        columns={colums}
        dataSource={dataSource}
        pagination={dataSource?.length > 10}
      />

      {mediaHasNote?.length > 0 && !isHide && (
        <div className="gx-mt-4">
          <h3>Heyet Notları</h3>
          <Table
            loading={isLoading}
            scroll={{ x: 1000 }}
            key={(record) => record.key}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <h4>Madde Açıklaması</h4>
                  <p>{record.mediaDescription}</p>
                </>
              ),
              rowExpandable: (record) => record.mediaDescription,
            }}
            bordered
            columns={noteColumn}
            dataSource={mediaHasNote}
            pagination={dataSource?.length > 10}
          />
        </div>
      )}

      {!isHide && userCanDecide && (
        <Row className="gx-mt-5">
          {/* <Col
            sm={24}
            md={12}
          >
            <Button block>Vazgeç</Button>
          </Col> */}
          <Col sm={24}>
            <Button
              block
              onClick={handleSubmit}
            >
              Gönder
            </Button>
          </Col>
        </Row>
      )}

      <CommentModal
        open={modalProps.visible}
        makeComment={makeComment}
        id={modalProps.id}
        data={modalProps.data}
        onCancel={() =>
          setModalProps({
            visible: false,
            data: null,
          })
        }
      />
      <NoteModal
        open={noteModal.visible}
        note={noteModal.data}
        onCancel={() =>
          setNoteModal({
            visible: false,
            data: null,
          })
        }
      />
      <UploadedMediaModal
        open={mediaModal.visible}
        data={mediaModal.data || []}
        onClose={() =>
          setMediaModal({
            visible: false,
            data: null,
          })
        }
        downloadBlob={handleBlobDownload}
        isContentSend
      />
    </>
  );
};

export default MediaOfferField;
