import { useMemo } from 'react';

import { Card, Col, Form, InputNumber, Row, Switch } from 'antd';
import { useGetOfferQuery, useGetSupervisorStiplatedTimeQuery } from 'services';
import { CircularProgress } from 'components/CircularProgress';

const TimeInfoCard = ({ offerID }) => {
  const { data, isLoading } = useGetSupervisorStiplatedTimeQuery();
  const { data: articleOrderDetail, isLoading: isArticleDetailLoading } = useGetOfferQuery(offerID);
  const form = Form.useFormInstance();

  const supervisorStiplatedTime = useMemo(() => {
    if (data && articleOrderDetail) {
      return data.find((item) => item.lengthKey === articleOrderDetail.articleLength);
    }
  }, [data, articleOrderDetail]);

  if (isLoading || isArticleDetailLoading) return <CircularProgress />;

  return (
    <Card>
      <Row
        align="middle"
        gutter={24}
        style={{
          flexDirection: 'row',
        }}
      >
        <Col md={8}>
          Madde Hacmi:{supervisorStiplatedTime?.lengthDisplay} - Süre:{supervisorStiplatedTime?.deliveryTimeDisplay}
        </Col>
        <Col
          md={16}
          className="gx-d-flex gx-align-items-center"
        >
          <Row
            gutter={24}
            align="middle"
            style={{
              flexDirection: 'row',
            }}
          >
            <Col md={16}>
              <div>Süre, madde hacmine göre otomatik belirlenir. Farklı bir süre belirlemek için “Süreyi Değiştir” seçeneği kullanabilirsiniz.</div>
            </Col>
            <Col
              md={4}
              className="gx-d-flex gx-align-items-center gx-justify-content-end"
            >
              <div className="gx-mr-1"> Süreyi Değiştir</div>
              <Form.Item
                name="changeDate"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col
              className="gx-d-flex gx-align-items-end"
              md={4}
            >
              <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.changeDate !== currentValues.changeDate}>
                {({ getFieldValue, setFieldsValue }) => {
                  const changeDate = getFieldValue('changeDate');

                  if (!changeDate) {
                    setFieldsValue({
                      deliveryDate: supervisorStiplatedTime?.deliveryTimeDisplay?.split(' ')[0],
                    });
                  }

                  return (
                    <Form.Item
                      name="deliveryDate"
                      initialValue={supervisorStiplatedTime?.deliveryTimeDisplay.split(' ')[0]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        step={1}
                        type="number"
                        onChange={(value) => {
                          if (value < 1 || isNaN(value)) {
                            form.setFieldsValue({
                              deliveryDate: 1,
                            });
                          }
                        }}
                        disabled={!changeDate}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <div className="gx-ml-2">Gün</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TimeInfoCard;
